import React from 'react'
import { NavLink } from 'react-router-dom'

const Logo = () => {
    return (
        <div className="logo">
            <NavLink to="/">
                <svg
                    aria-label="Home Page"
                    width="386.1"
                    height="93.6269208493867"
                    viewBox="0 0 350 84.87288862285767"
                >
                    <g
                        id="1"
                        transform="matrix(2.020831151046024,0,0,2.020831151046024,-8.73019038614582,-36.37294099775309)"
                        fill="#ffffff"
                    >
                        <path d="M25.381 39 l21.001 -21.001 l0 41.999 l-6.9599 0 l0 -25.115 l-14.041 14.041 l-21.061 -21.152 l0 -9.7725 z M45.068 58.6882 l-0.000058594 -37.522 l-18.762 18.761 l-0.92574 0.92574 l-0.927 -0.92442 l-18.823 -18.769 l0 6.0709 l19.751 19.837 l15.349 -15.349 l0 26.969 l4.3375 0 z" />
                    </g>
                    <g
                        id="2"
                        transform="matrix(1.4196314601957187,0,0,1.4196314601957187,100.9114612321724,5.9658426831507825)"
                        fill="#ffffff"
                    >
                        <path d="M2.88 12.059999999999999 l4.64 4.6008 l0 18.6 l-4.64 4.68 l0 -27.881 z M6.6448 34.900800000000004 l0 -17.877 l-2.8917 -2.8663 l0 23.66 z M28.06 35.3204 l13.999 0 l0 4.6802 l-18.639 0 z M41.186 39.12624 l0 -2.9319 l-12.762 0 l-2.9065 2.9319 l15.668 0 z M28.06 12 l0 16.982 l-4.64 4.68 l0 -21.662 l4.64 0 z M27.186 28.622 l0 -15.749 l-2.8917 0 l0 18.665 z M57.96000000000001 12.059999999999999 l4.64 4.6008 l0 18.6 l-4.64 4.68 l0 -27.881 z M61.7248 34.900800000000004 l0 -17.877 l-2.8917 -2.8663 l0 23.66 z M78.5 39.61492 l4.6392 -4.2956 l14 0 l0 4.6802 l-18.639 0 l0 -0.38461 z M96.266 36.1944 l-12.783 0 l-3.167 2.9319 l15.95 0 l0 -2.9319 z M78.5 12 l18.64 0 l0 4.6802 l-14 0 z M96.266 15.806000000000001 l0 -2.9319 l-15.668 0 l2.9065 2.9319 l12.762 0 z M78.5 28.34 l0 -4.64 l18.639 0 l0 4.64 l-18.639 0 z M79.3741 24.573999999999998 l0 2.8917 l16.891 0 l0 -2.8917 l-16.891 0 z M119.05120000000001 11.998999999999999 l7.9476 10.208 l-2.945 3.792 l-10.974 -14 l5.9712 0 z M124.05 24.578 l1.8409 -2.3707 l-7.2676 -9.334 l-3.7483 0 z M113.08 39.99875 l10.974 -14 l2.945 3.7929 l-7.9476 10.208 l-5.9712 0 z M118.62360000000001 39.1246 l7.2676 -9.334 l-1.8409 -2.3707 l-9.1752 11.705 l3.7483 0 z M140.919 11.998999999999999 l-10.934 14 l-2.986 -3.792 l7.9888 -10.208 l5.931 0 z M128.11 22.206 l1.8724 2.3768 l9.1444 -11.709 l-3.7134 0 z M126.999 29.792 l2.986 -3.7929 l10.934 14 l-5.931 0 z M135.414 39.12456 l3.7134 0 l-9.1444 -11.709 l-1.8724 2.3776 z M156.82 39.61492 l4.6392 -4.2956 l14 0 l0 4.6802 l-18.639 0 l0 -0.38461 z M174.586 36.1944 l-12.783 0 l-3.167 2.9319 l15.95 0 l0 -2.9319 z M156.82 12 l18.64 0 l0 4.6802 l-14 0 z M174.586 15.806000000000001 l0 -2.9319 l-15.668 0 l2.9065 2.9319 l12.762 0 z M156.82 28.34 l0 -4.64 l18.639 0 l0 4.64 l-18.639 0 z M157.6941 24.573999999999998 l0 2.8917 l16.891 0 l0 -2.8917 l-16.891 0 z" />
                    </g>
                </svg>
            </NavLink>
        </div>
    )
}

export default Logo
